import { TextInput } from '@/components/base/text-input';
import { ChangeEventHandler } from 'react';

interface Props {
  id?: string;
  value: string;
  onChange: ChangeEventHandler;
  placeholder?: string;
  password?: boolean;
  label: string;
}

export function LabelledTextInput({
  id, value, onChange, placeholder, password, label,
}: Props) {
  return (
    <div className="flex flex-col">
      <label
        htmlFor={id}
        className="pl-1 py-1 text-sm font-title text-stone-900"
      >
        {label}
      </label>
      <TextInput id={id} value={value} onChange={onChange} placeholder={placeholder} password={password} />
    </div>
  );
}

LabelledTextInput.defaultProps = {
  id: undefined,
  placeholder: '',
  password: false,
};
