import { useRouter } from 'next/router';
import { useState } from 'react';
import Link from 'next/link';
import { Button } from '@/components/base/button';
import { LabelledTextInput } from '@/components/base/labelled-text-input';
import { login } from '@/backend/auth/auth';
import { AxiosError } from 'axios';
import { InferType, object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from '@sentry/nextjs';

const loginSchema = object({
  email: string().trim().email('Bitte gib eine gültige E-Mail-Adresse an!').required(),
  password: string().trim().required(),
});

type LoginFormData = InferType<typeof loginSchema>;

export default function Login() {
  const [errorType, setErrorType] = useState<'auth' | 'other' | null>(null);

  const {
    handleSubmit, formState: { errors }, control,
  } = useForm<LoginFormData>({
    resolver: yupResolver(loginSchema),
    defaultValues: { email: '', password: '' },
  });

  const router = useRouter();
  async function onSubmit({ email, password }: LoginFormData) {
    try {
      await login(email, password);
      if (router.pathname === '/login') {
        router.push('/wochen');
      }
      setErrorType(null);
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        Sentry.captureMessage(`login: wrong email ${email} or password`);
        setErrorType('auth');
      } else {
        Sentry.captureException(error);
        setErrorType('other');
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="bg-white sm:bg-stone-200 w-screen h-screen flex items-center justify-center">
        <div className="mx-4 h-fit flex flex-col gap-4 w-full max-w-md bg-white px-8 py-16 rounded-xl">
          <img className="w-1/2 mx-auto mb-10" src="../logo.svg" alt="Logo" />
          <Controller
            name="email"
            render={({ field }) => (
              <div>
                <LabelledTextInput
                  {...field}
                  label="E-Mail"
                  placeholder="Deine E-Mail Adresse"
                />
                {errors.email && <span className="p-3 text-red-500 text-sm">{errors.email.message}</span>}
              </div>
            )}
            defaultValue=""
            control={control}
          />
          <Controller
            name="password"
            render={({ field }) => (
              <LabelledTextInput
                {...field}
                label="Passwort"
                password
                placeholder="Dein Passwort"
              />
            )}
            defaultValue=""
            control={control}
          />
          { errorType === 'auth'
            && (
            <div className="text-red-700 font-bold rounded-xl">
              <p>Wir konnten Dich nicht anmelden.</p>
              <p>Bitte überprüfe E-Mail und Passwort!</p>
            </div>
            )}
          { errorType === 'other'
            && (
            <div className="text-red-700 font-bold rounded-xl">
              <p>Es ist ein technisches Problem aufgetreten.</p>
              <p>Bitte versuche es später noch einmal!</p>
            </div>
            )}
          <div className="text-right text-stone-700 hover:text-stone-900">
            <Link href="/passwort-vergessen">Passwort vergessen?</Link>
          </div>
          {/* TODO disable if error */}
          <Button label="Anmelden" onClick={handleSubmit(onSubmit)} inputType="submit" />
        </div>
      </div>
    </form>
  );
}
