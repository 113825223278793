import { QueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { AuthToken, sendLogin, sendRefresh } from '@/backend/api/auth-api';
import jwtDecode, { JwtPayload } from 'jwt-decode';

const queryClient = new QueryClient({ defaultOptions: { queries: { cacheTime: Infinity, staleTime: Infinity } } });

function saveTokenToLocalStorage(token?: AuthToken) {
  if (token === undefined) {
    localStorage.removeItem('token');
  } else {
    localStorage.setItem('token', JSON.stringify(token));
  }
}

export function setAccessToken(accessToken: string) {
  const token: AuthToken = { accessToken, refreshToken: '' };
  queryClient.setQueryData('token', token);
  saveTokenToLocalStorage(token);
}

export async function login(email: string, password: string) {
  await queryClient.fetchQuery({
    queryKey: ['login-temp'],
    queryFn: () => sendLogin({ username: email, password }),
  });

  const token = queryClient.getQueryData('login-temp') as AuthToken;
  queryClient.setQueryData('token', token);
  queryClient.removeQueries(['login-temp']);

  saveTokenToLocalStorage(token);
}

// TODO no need to be async
export async function logout() {
  saveTokenToLocalStorage(undefined);
  queryClient.setQueryData('token', undefined);
}

async function refresh(refreshToken: string) {
  await queryClient.prefetchQuery({
    queryKey: ['refresh-temp'],
    queryFn: () => sendRefresh(refreshToken),
  });

  const token = queryClient.getQueryData<AuthToken>('refresh-temp');
  queryClient.setQueryData('token', token);
  queryClient.removeQueries(['refresh-temp']);

  saveTokenToLocalStorage(token);

  return token;
}

function expires(jwt: string, thresholdMs: number) {
  if (jwt) {
    const { exp } = jwtDecode<JwtPayload>(jwt);
    return !!exp && (exp * 1000 < new Date().getTime() + thresholdMs);
  }

  return true;
}

export async function getAccessToken() {
  const token = queryClient.getQueryData<AuthToken>('token');

  if (token === undefined) {
    logout();
    return undefined;
  }

  // access token is very close to expiry (30s)
  if (expires(token.accessToken, 30 * 1000)) {
    console.log('access token about to expire');
    if (expires(token.refreshToken, 0)) {
      logout();
      return undefined;
    }
    const newToken = await refresh(token.refreshToken);
    console.log('new token from refres token', newToken);
    if (!newToken) {
      logout();
      return undefined;
    }
    return newToken.accessToken;
  }

  // refresh in background if access token expires within two minutes
  if (expires(token.accessToken, 2 * 60 * 1000)) {
    console.log('refreshing in background');
    refresh(token.refreshToken);
  }

  return token.accessToken;
}

export function useToken() {
  const existingToken = queryClient.getQueryData<AuthToken>('token');
  const [token, setToken] = useState<AuthToken | undefined>(existingToken);

  useEffect(() => {
    const unsubscribe = queryClient.getQueryCache().subscribe(() => {
      console.log('listener');
      console.log('newToken: ', queryClient.getQueryData<AuthToken>(['token']));
      const newToken = queryClient.getQueryData<AuthToken>(['token']);

      if (!isEqual(token, newToken)) {
        setToken(newToken);
      }
    });

    return () => {
      unsubscribe();
    };
  });

  return token;
}

export async function initTokenFromLocalStorage() {
  const storedTokenString = localStorage.getItem('token');

  if (storedTokenString) {
    queryClient.setQueryData('token', JSON.parse(storedTokenString));
  }

  await getAccessToken();
}
