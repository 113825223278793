import '@/styles/globals.css';

import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthWrapper } from '@/components/auth-wrapper';
import { NextComponentType, NextPageContext } from 'next';
import { LayoutFunction } from '@/components/layout';
import { CookieConsent } from '@/components/cookie-consent';

config.autoAddCss = false;

const queryClient = new QueryClient();

type AppProps = {
  pageProps: any
  Component: NextComponentType<NextPageContext, any> & { layout: LayoutFunction }
};

function Layout({ Component, pageProps }: AppProps) {
  if (Component.layout) {
    return Component.layout(<Component {...pageProps} />);
  }
  return <Component {...pageProps} />;
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthWrapper>
        <Layout Component={Component} pageProps={pageProps} />
      </AuthWrapper>
      <CookieConsent />
    </QueryClientProvider>
  );
}
