import Login from '@/pages/login';
import { PropsWithChildren, useEffect, useState } from 'react';
import { initTokenFromLocalStorage, useToken } from '@/backend/auth/auth';
import { useRouter } from 'next/router';

export function AuthWrapper({ children }: PropsWithChildren) {
  const { pathname, isReady } = useRouter();

  const token = useToken();
  const [isLoggedIn, setIsLoggedIn] = useState(token !== undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [isReady]);

  useEffect(() => {
    initTokenFromLocalStorage();
  }, []);

  useEffect(() => {
    setIsLoggedIn(token !== undefined);
  }, [token]);

  if (isLoading) {
    return null;
  }

  if (isLoggedIn || ['/passwort-vergessen'].includes(pathname)
      || pathname.startsWith('/passwort-zuruecksetzen')
      || pathname.startsWith('/login-als')
      || pathname.startsWith('/datenschutz')
      || pathname.startsWith('/onboarding')
      || pathname.startsWith('/vorschau')) {
    return <div>{children}</div>;
  }

  return <Login />;
}
