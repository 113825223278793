import { ChangeEventHandler } from 'react';

interface Props {
  id?: string;
  value: string;
  onChange: ChangeEventHandler;
  placeholder?: string;
  password?: boolean;
}

export function TextInput({
  id, value, onChange, placeholder, password,
}: Props) {
  const shapeClasses = 'p-3 rounded-xl border border-stone-500 bg-white appearance-none shadow-sm';
  const textClasses = 'text-stone-900 placeholder-stone-500';
  const focusClasses = 'focus:border-0 focus:outline-none focus:ring-1 focus:ring-stone-900 peer';
  return (
    <input
      type={password ? 'password' : 'text'}
      id={id}
      value={value}
      onChange={onChange}
      className={[shapeClasses, textClasses, focusClasses].join(' ')}
      placeholder={placeholder}
    />
  );
}

TextInput.defaultProps = {
  id: undefined,
  placeholder: '',
  password: false,
};
