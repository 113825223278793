import Link from 'next/link';
import { Button } from '@/components/base/button';
import { useEffect, useState } from 'react';

export function CookieConsent() {
  const [consentGiven, setConsentGiven] = useState(true);

  useEffect(() => {
    const storedConsentGiven = localStorage.getItem('cookieConsentGiven') === 'true';
    setConsentGiven(storedConsentGiven);
  }, []);

  return (consentGiven ? null : (
    <div className="fixed bottom-0 w-full flex justify-center gap-4 p-4 rounded-2xl z-100 bg-white items-center">
      <div>
        Wir verwenden notwendige Cookies, um Dir die bestmögliche Erfahrung auf unserer Website zu bieten.
        {' '}
        <Link className="font-bold underline" href="/datenschutz-extern">Hier</Link>
        {' '}
        kannst Du mehr darüber erfahren, welche Cookies wir verwenden
      </div>
      <Button
        onClick={() => {
          setConsentGiven(true);
          localStorage.setItem('cookieConsentGiven', 'true');
        }}
        label="Verstanden"
      />
    </div>
  )
  );
}
