import axios from 'axios';

export interface LoginDto {
  username: string;
  password: string;
}
export interface AuthToken {
  accessToken: string;
  refreshToken: string;
}

export async function sendLogin(dto: LoginDto): Promise<AuthToken> {
  return (await axios.post(`${process.env.NEXT_PUBLIC_SERVICES_URL}/auth/user/login`, dto)).data;
}

export async function sendRefresh(refreshToken: string): Promise<AuthToken> {
  return (await axios.post(
    `${process.env.NEXT_PUBLIC_SERVICES_URL}/auth/user/refresh`,
    {},
    { headers: { Authorization: `Bearer ${refreshToken}` } },
  )).data;
}
