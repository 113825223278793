import { MouseEventHandler } from 'react';

interface Props {
  label: string;
  onClick:MouseEventHandler;
  disabled?: boolean;
  inputType?: 'button' | 'submit';
  wFull?: boolean;
}

export function Button({
  label, onClick, disabled, inputType, wFull,
}: Props) {
  const shapeClasses = 'bg-mk rounded-xl px-6 py-3 shadow-sm';
  const textClasses = 'font-title text-2xl text-stone-900';
  const hoverClasses = disabled ? '' : 'hover:bg-mk-600 hover:text-black';
  const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : '';
  const widthClass = wFull ? 'w-full' : '';

  if (inputType === 'submit') {
    return (
      <button
        type="submit"
        onClick={(e) => {
          if (!disabled) onClick(e);
        }}
        className={[shapeClasses, textClasses, hoverClasses, disabledClasses, widthClass].join(' ')}
      >
        {label}
      </button>
    );
  }

  return (
    <button
      type="button"
      onClick={(e) => {
        if (!disabled) onClick(e);
      }}
      className={[shapeClasses, textClasses, hoverClasses, disabledClasses, widthClass].join(' ')}
    >
      {label}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  inputType: 'button' as 'button' | 'submit',
  wFull: false,
};
